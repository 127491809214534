import UserMenu from '@/components/UserMenu.vue'
// import PapersetCreator from '@/components/PapersetCreator.vue'
import { defineComponent } from '@vue/composition-api'
import { Paperset, UserProfile } from '@/models'

export default defineComponent({
  name: 'AppBar',
  components: {
    UserMenu
    // PapersetCreator
  },
  props: {
    allowSearch: Boolean,
    navigation: Boolean,
    showNote: Boolean,
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    userProfile: { type: Object as () => UserProfile, default: () => undefined }
  },
  data: () => ({
    openNewSetModal: false
  }),

  methods: {
    onNewPaperSet (newSet: Partial<Paperset>) {
      this.openNewSetModal = false
      this.$emit('onNewPaperSet', newSet)
    }
  },

  render () {
    const appBarIcon = (this.navigation && this.userProfile) ? (
      <v-app-bar-nav-icon
        onClick={ () => { this.$emit('toggle') } }
      />
    ) : null
    const searchField = this.allowSearch ? (
      <v-text-field
        solo flat dense outlined hide-details
        label="Search in this Paperset"
        append-icon="mdi-magnify"
        class="pa-0 ma-0"
        {...{ on: { 'click:append': this.$emit('search') } }}
      />
    ) : null

    const userMenu = this.userProfile !== undefined ? (
      <div>
        {
          this.showNote
            ? <v-btn
              icon
              color="primary"
              title="My Notes"
              onClick={ () => { this.$emit('note') }}
            >
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
            : null
        }

        <v-btn
          icon
          class="mr-1"
        >
          <UserMenu user={ this.userProfile } />
        </v-btn>
      </div>
    ) : null

    const appTitle = this.title ? (
      <v-btn text title={this.description}>
        { this.title }
        <v-icon
          small
          color="grey"
          class="mx-2"
        >mdi-information</v-icon>
      </v-btn>
    ) : null

    return (
      <div>
        <v-app-bar
          app
          dense
          clipped-left
          clipped-right
          flat
          class="topnav"
          color="#fff"
          style="border-bottom: 1px ridge #ddd"
        >
          { appBarIcon }
          <v-toolbar-title class="pl-2 subtitle-1 font-weight-bold">
            {
              this.title !== undefined
                ? (
                  <div>
                    <router-link
                      to="/"
                      style="text-decoration: none; color: #1976D2; font-size: 1.28em;"
                    >
                        Paperset
                    </router-link>
                    <v-chip
                      class="ml-1 mb-5 px-2"
                      color="purple"
                      dark
                      x-small
                    >
                        beta
                    </v-chip>
                  </div>
                ) : null
            }
          </v-toolbar-title>
          {/* <v-btn
            icon
            color="primary"
            title="Add new paper set"
            onClick={ () => { this.openNewSetModal = true }}
          >
            <v-icon>mdi-plus-box-multiple</v-icon>
          </v-btn> */}

          <v-spacer />
          <v-toolbar-title class="px-2 font-weight-bold app-title">
            {
              this.title === undefined
                ? (
                  <div>
                    <router-link
                      to="/"
                      style="text-decoration: none; color: #1976D2; font-size: 1.28em;"
                    >
                      PaperSet
                    </router-link>
                    <v-chip
                      class="ml-1 mb-5 px-2"
                      color="purple"
                      dark
                      x-small
                    >
                      beta
                    </v-chip>
                  </div>
                ) : null
            }
            { appTitle }
          </v-toolbar-title>
          {/* <router-link
            class="header-link"
            to="/public/featured"
          >
            Explore
          </router-link>
          <router-link
            class="header-link"
            to="/public/about"
          >
            About
          </router-link> */}
          { searchField }

          <v-spacer />
          { userMenu }

        </v-app-bar>
        {/* <v-dialog
          v-model={ this.openNewSetModal }
          width="80%"
        >
          <v-card>
            <v-toolbar
              flat
              dense
            >
              <v-spacer />
              <v-btn
                text
                onClick={ () => { this.openNewSetModal = false } }
              >
                <v-icon>mdi-close</v-icon>Close
              </v-btn>
            </v-toolbar>
            <PapersetCreator onCreate={ this.onNewPaperSet } />
          </v-card>
        </v-dialog> */}
      </div>
    )
  }
})
