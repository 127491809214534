
import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import { getUser } from '@/Firebase'
import { UserInfo, UserProfile } from '@/models'

export default defineComponent({
  name: 'UserMenu',
  props: {
    user: { type: Object as () => UserInfo | UserProfile, default: null }
  },
  setup (props) {
    const state: {currUser: UserInfo | UserProfile | null} = reactive({
      currUser: computed(() => props.user ?? getUser())
    })

    return { ...toRefs(state) }
  }
})
