import { PaperAuthor, serverTimestamp } from '@/models'

export function showAuthorNames (authors: PaperAuthor[]): string | undefined {
  if (Array.isArray(authors)) {
    return authors.map(author => {
      if (Array.isArray(author.given)) {
        author.given = author.given.join(' ')
      }
      return [author.given, author.family].join(' ')
    }).join(', ')
  }
}

export function showKeywords (keywords: string[]): string {
  return (Array.isArray(keywords)) ? keywords.join(', ') : keywords
}

export function showDateTimeInfo (lastUpdated: serverTimestamp): string {
  if (lastUpdated === undefined) return ''
  let elapsed =
    (new Date().getTime() - lastUpdated.toDate().getTime()) * 0.001

  let suffix = ' seconds'

  if (elapsed > 60) {
    elapsed = elapsed / 60
    suffix = ' minute'
  }
  if (elapsed > 60) {
    elapsed = elapsed / 60
    suffix = ' hour'
  }
  if (elapsed > 24) {
    elapsed = elapsed / 24
    suffix = ' day'
  }
  if (elapsed > 30) {
    elapsed = elapsed / 30
    suffix = ' month'
  }
  if (elapsed > 365) {
    elapsed /= 365
    suffix = ' year'
  } else if (elapsed > 30) {
    elapsed /= 30
    suffix = ' year'
  }
  if (elapsed > 1) {
    suffix += 's'
  }
  return elapsed.toFixed(0) + suffix + ' ago'
  // let dataString = date.toLocaleDateString('en-US', {
  //   year: 'numeric', month: 'long',
  //   day: 'numeric', hour: 'numeric', minute: 'numeric'
  // })
}
